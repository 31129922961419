import ServicePageFooterBidmatic from '@adtelligent-fe/react-common/src/components/service-page-container/components/service-page-footer-bidmatic';
import { Sidebar } from '@adtelligent-fe/react-common/src/components/sidebar';
import type { MenuItem } from 'primereact/menuitem';
import React, { type FC } from 'react';
import { Outlet } from 'react-router-dom';

import { HeaderMenu } from '../header-navbar';

interface ProtectedLayoutProps {
  contentLayout?: FC;
  sidebarItems?: MenuItem[];
}

const ProtectedLayout: FC<ProtectedLayoutProps> = ({ contentLayout: ContentLayout, sidebarItems }) => {
  return (
    <div className="flex flex-col min-h-screen bg-[--body-bg]">
      <HeaderMenu />
      <main className="flex grow overflow-hidden">
        {sidebarItems?.length ? <Sidebar items={sidebarItems} /> : <></>}
        <section className="flex flex-col overflow-auto flex-1 p-6">
          {ContentLayout ? <ContentLayout /> : <Outlet />}
        </section>
      </main>
      <ServicePageFooterBidmatic />
    </div>
  );
};

export default ProtectedLayout;
