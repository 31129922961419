import classNames from 'classnames';
import type { PrimeReactPTOptions } from 'primereact/api';
import type { ButtonPassThroughMethodOptions } from 'primereact/button';

export const themeProviderConfig: PrimeReactPTOptions = {
  inputtext: {
    root: ({ props }) => ({
      className: classNames(
        '!py-1 px-2 h-8 border border-gray-25 bg-light placeholder-gray-50 placeholder:text-sm text-dark placeholder:text-gray-50 text-sm focus:outline-0 focus-visible:outline-0 hover:border-blue selected:bg-light internal-autofill-selected:bg-light focus-visible:border-blue focus:shadow-[0_0_0_3px_#BBDEFB40] focus-visible:bg-light active:border-blue rounded-sm w-full',
        { 'w-[314px]': props.size === 'large', 'opacity-60': props.disabled }
      )
    }),
    label: { className: 'text-gray-25 text-sm p-0 m-0' }
  },
  floatlabel: {
    root: {
      className: classNames(
        'block relative w-full', // root component style
        'text-sm text-disabled-state [&>label]:absolute [&>label]:pointer-events-none [&>label]:left-2 [&>label]:top-1/2 [&>label]:-mt-2 [&>label]:leading-none [&>label]:transition-all [&>label]:ease-in-out', // label style
        '[&>textarea~label]:top-4', // textarea
        '[&>input:focus~label]:-top-3 [&>input:focus~label]:-left-0 [&>input:focus~label]:text-sm', // input focus
        '[&>input:autofill~label]:-top-3 [&>input:autofill~label]:text-sm', // input autofill
        '[&>input.p-filled~label]:-top-3 [&>input.p-filled~label]:text-sm', // input filled
        '[&>textarea:focus~label]:-top-3 [&>textarea:focus~label]:-left-0 [&>textarea:focus~label]:text-sm', // textarea focus
        '[&>textarea.p-filled~label]:-top-3 [&>textarea.p-filled~label]:text-sm', // textarea filled
        '[&>div[data-pc-name="dropdown"][data-p-focus="false"]~label]:-top-3 [&>div[data-pc-name="dropdown"][data-p-focus="true"]~label]:-top-3 [data-pc-name="dropdown"][data-p-focus="true"]~label]:-left-0 [&>div[data-pc-name="dropdown"][data-p-focus="false"]~label]:-left-0 [&>div[data-pc-name="dropdown"][data-p-focus="false"]~label]:text-sm', // dropdown focus
        '[&>input::placeholder]:opacity-1 [&>input::placeholder]:transition-all [&>input::placeholder]:ease-in-out', // placeholder
        '[&>input::placeholder:focus]:opacity-100 [&>input::placeholder:focus]:transition-all [&>input::placeholder:focus]:ease-in-out' // placeholder focus
      )
    }
  },
  dropdown: ({ props }) => ({
    root: {
      className: classNames(
        'cursor-pointer inline-flex relative select-none bg-content-primary-bg p-2 border border-content-border transition-colors rounded-md text-sm w-full',
        { 'opacity-60': props.disabled }
      )
    },
    panel: 'w-max border-content-border border border rounded-md shadow-sm !bg-content-primary-bg mt-0.5',
    label: {
      className: 'text-content-primary-bg text-sm focus:outline-0 focus-visible:outline-0'
    },
    wrapper: {
      className: 'mt-[2px] shadow-[0_3px_6px_0_rgba(0,0,0,0.26)] rounded-md overflow-auto'
    },
    input: {
      className:
        'cursor-pointer flex flex-auto overflow-hidden overflow-ellipsis whitespace-nowrap relative text-content-color font-normal'
    },
    list: {
      className: 'py-1 pt-0 pb-0 list-none m-0 bg-content-primary-bg rounded-md max-h-[228px]'
    },
    item: ({ context }) => ({
      className: classNames(
        'p-4 m-0 bg-content-primary-bg !h-auto text-sm hover:cursor-pointer text-h2-header hover:bg-accent-light',
        {
          'text-h2-header': context.focused && !context.selected,
          '!bg-accent-default !text-content-primary-bg': context.selected
        }
      )
    }),
    trigger: {
      className: classNames(
        'flex items-center justify-center shrink-0 bg-transparent text-disabled-state w-[14px] rounded-tr-lg rounded-br-lg'
      )
    },
    filtericon: '-mt-2 absolute top-1/2 right-3',
    filterclearicon: 'top-1/2 right-12 absolute -mt-2',
    clearicon: 'text-disabled-state0 right-8 -mt-2 absolute top-1/2 w-[14px]',
    filtercontainer: 'relative',
    filterinput: ({ props }) => ({
      className: classNames(
        '!py-1 px-2 h-8 border border-content-border bg-content-primary-bg placeholder-disabled-state placeholder:text-sm text-content-color placeholder:text-disabled-state text-sm focus:outline-0 focus-visible:outline-0 hover:border-accent-default selected:bg-content-primary-bg internal-autofill-selected:bg-content-primary-bg focus-visible:border-accent-default focus:shadow-[0_0_0_3px_#BBDEFB40] focus-visible:bg-content-primary-bg active:border-accent-default rounded-sm w-full',
        {
          'w-[314px]': props.size === 'large',
          'opacity-60': props.disabled
        }
      )
    }),
    itemgroup: {
      className: classNames('m-0 p-3 text-h2-header bg-content-primary-bg font-bold', 'cursor-auto')
    },
    dropdownicon: 'text-disabled-state',
    header: {
      className: classNames('text-disabled-state bg-content-secondary-bg rounded-t-md')
    },
    emptymessage: 'p-2'
  }),
  button: {
    root: ({ props, context }: ButtonPassThroughMethodOptions) => {
      return {
        className: classNames(
          'flex items-center focus:outline-0 focus-visible:outline-0 font-bold rounded-sm cursor-pointer disabled:cursor-auto py-2 px-4',
          {
            'hover:bg-primary-bg px-4 py-2 text-primary text-sm disabled:opacity-60': props.text,

            'bg-transparent text-success hover:bg-success-bg-hover hover:text-success-hover':
              props.severity === 'success',
            'hover:bg-primary-hover bg-primary text-content-primary-bg text-sm focus-visible:bg-primary-hover focus:bg-primary-hover':
              props.severity === null && !props.text && !props.link && !props.rounded,
            'bg-transparent border border-primary text-primary hover:bg-primary-bg hover:text-primary-hover hover:border-primary-hover':
              props.severity === 'secondary',
            'bg-transparent text-primary hover:bg-primary-bg hover:text-primary-hover hover:border-primary-hover':
              props.severity === 'info',
            'px-4 py-2 bg-transparent text-primary hover:text-primary-hover hover:bg-primary-bg': props.link
          },
          {
            'py-3 px-6 text-[16px] leading-[19px]': props.size === 'large',
            'py-2 px-4 text-[14px] leading-[16px] font-normal': props.size === 'small'
          },
          {
            'rounded-full bg-transparent': props.rounded
          },
          {
            'bg-primary-disabled !text-content-color pointer-events-none cursor-default':
              context.disabled && props.severity === null,
            'border-primary-disabled bg-transparent !text-content-color pointer-events-none cursor-default':
              context.disabled && props.severity === 'secondary'
          }
        )
      };
    },
    label: ({ props }) => ({
      className: classNames({
        'font-normal': props.size === undefined,
        'font-medium': props.size === 'large',
        'text-sm leading-[16px]': props.link
      })
    })
  }
};
